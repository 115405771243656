export const routes = [
    {
        path: '/inflow_items',
        name: 'inflow_items.browse',
        component: () => import(/* webpackChunkName: "InflowItemsBrowse" */ '@/views/app/InflowItemsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inflow_items/create',
        name: 'inflow_items.create',
        component: () => import(/* webpackChunkName: "InflowItemsActions" */ '@/views/app/InflowItemsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inflow_items/:id/edit',
        name: 'inflow_items.edit',
        component: () => import(/* webpackChunkName: "InflowItemsActions" */ '@/views/app/InflowItemsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/inflow_items/:id/delete',
        name: 'inflow_items.delete',
        component: () => import(/* webpackChunkName: "InflowItemsActions" */ '@/views/app/InflowItemsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]